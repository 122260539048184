
<template>
  <div class="supplementProtocol">
    <!-- AGT006：附件四:《合规经营承诺书》 -->
    <div class="content">
      <div class="title center bold mt3">农村普惠金融业务合规经营承诺书</div>
      <div class="mt3">为促进普惠金融服务健康合规发展，现做出以下承诺：</div>
      <div class="indent5">1.保证合法合规开展业务，不向客户收取任何额外费用，不利用合作方银行与村口科技名义牟取不正当收益。</div>
      <div class="indent5">2.保证不参与任何形式的民间借贷、非法集资、高利贷、违规担保或者其他高危金融活动。</div>
      <div class="indent5">3.保证不打白条办理业务，不挪用客户资金，保证客户办理业务实时到账。</div>
      <div class="indent5">4.保证不代客户保管银行卡、存折、网银认证工具等产品，不代客户签名及设置或修改密码。</div>
      <div class="indent5">5.保证不以任何方式泄露商业秘密和客户资料，不损害客户、合作方银行和村口科技的正当利益。</div>
      <div class="indent5">6.保证不同时经营其他银行或其他与村口科技普惠金融相关业务。</div>
      <div class="indent5">7.保证不参与黄、赌、毒活动及邪教组织。</div>
      <div class="indent5">8.本人自愿遵守本承诺，接受大家监督。若有违反，本人自愿承担撤除服务点、追究法律责任等后果。</div>
      <div class="mt3">商户名称：{{agreementParams.para_license_companyName}}</div>
      <div class="mt3">承诺人：{{agreementParams.para_license_ownerName}}</div>
      <div style="float: right">承诺日期： {{agreementParams.para_date_year}} 年  {{agreementParams.para_date_month}}  月  {{agreementParams.para_date_day}}  日</div>
    </div>
    <div class="footer" v-show="signatureShow">
      <van-button type="info" color="#3F7C53" @click="signClick">签名</van-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast} from 'vant'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  data(){
    return{
      signatureShow: false,
      agreementParams: {}
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.signatureShow = this.$route.query.sign == 'sign' ? true : false
      if(this.$route.query.agreementParams){
        this.agreementParams = JSON.parse(this.$route.query.agreementParams)
      }
    })
    uwStatisticAction('/agreement/supplementProtocol','附件四：农村普惠金融业务合规经营承诺书')
  },
  methods:{
    signClick() {
      this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
    }
  }
}
</script>

<style lang="less" scoped>
.supplementProtocol{
  background: #f5f5f5;
  font-size: 0.3rem;
  box-sizing: border-box;
  padding: 20px 0.3rem 100px 0.3rem ;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  .content{
    .title{
      font-size: 0.4rem;
    }
    .bold{
      font-weight: bold
    }
    .center{
      text-align: center;
    }
    .indent5{
      text-indent: 0.6rem;
    }
    .mt3{
      margin-top: 0.3rem;
    }
    .underline{
      text-decoration: underline;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .van-button{
      width: 100%;
    }
  }
}
</style>
